<template>
  <div class="beta__badge">Beta</div>
</template>

<script setup></script>

<style scoped lang="scss">
.beta__badge {
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 9999px;
  border: 1px solid #bfdbfe;
  font-size: 8px;
  line-height: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  align-self: center;
  color: #2563eb;
}
</style>
