<template>
  <div class="setting__container">
    <div class="setting__title">Custom fields</div>

    <div class="add-custom-field__container">
      <SelectDropdown :items="customFieldsStore.inactiveCustomFields" @update:value="onValueUpdate">
        <template #button="{ toggleDropdown, isOpen }">
          <div class="add-custom-field__button" @click="toggleDropdown">
            <div class="plus-icon">
              <PlusIcon color="#fff" size="20" />
            </div>
            <div class="add-custom-field__button-text">Add a custom field</div>
          </div>
        </template>
        <template #item="item">
          <BuildingIcon class="dropdown-icon" v-if="item.fieldType === 'company'" />
          <ContactIcon class="dropdown-icon" v-else-if="item.fieldType === 'contact'" />
          {{ item.label }}
        </template>
      </SelectDropdown>
      <div class="setting__subtitle">
        <InfoIcon class="trash-icon" size="20" />Refresh the list to see new custom fields
      </div>
    </div>
    <div class="setting__body">
      <div v-if="customFieldsStore.loading && !customFieldsStore.resyncLoading" class="loader">
        <Loader :size="20"></Loader>
      </div>
      <div
        v-else
        v-for="customField in customFieldsStore.activeCustomFields"
        :key="customField.name"
        class="setting__item"
      >
        <div class="setting__item-title">
          <div class="setting__item-text">
            <BuildingIcon class="dropdown-icon" v-if="customField.fieldType === 'company'" />
            <ContactIcon class="dropdown-icon" v-else-if="customField.fieldType === 'contact'" />
            {{ customField.label }}
          </div>
          <div class="setting__remove-custom-field" @click="removeCustomField(customField)">
            <TrashIcon class="trash-icon" size="20" />
          </div>
        </div>
        <div
          v-if="customField.active && customField.type === 'dropdown' && customField.options.length"
          class="setting__dropdown-section"
        >
          <dropdown
            :items="customField.options.map((item) => ({ label: item.label, id: item.value }))"
            :initial-value="
              customField.options.map((item) => ({ label: item.label, id: item.value }))[0]
            "
            :disabled="!!customFieldsStore.resyncLoading"
          />

          <div v-if="customFieldsStore.resyncLoading === customField.name">
            <Loader :size="20"></Loader>
          </div>
          <div
            class="resync__button"
            @click="resync(customField)"
            v-else-if="!customFieldsStore.resyncLoading"
          >
            <RefreshIcon size="16" /> resync
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useCustomFieldsStore } from '@/stores/customFieldsStore'
import RefreshIcon from '../icons/RefreshIcon.vue'
import PlusIcon from '../icons/PlusIcon.vue'
import ContactIcon from '../icons/ContactIcon.vue'
import BuildingIcon from '../icons/BuildingIcon.vue'
import TrashIcon from '../icons/TrashIcon.vue'
import InfoIcon from '../icons/InfoIcon.vue'
import Dropdown from '../Dropdown.vue'
import SelectDropdown from '../SelectDropdown.vue'
import Loader from '../Loader.vue'

async function onValueUpdate(customField) {
  await customFieldsStore.addCustomField(customField)
}

async function removeCustomField(customField) {
  await customFieldsStore.deleteCustomField(customField)
}

async function resync(customField) {
  if (customFieldsStore.resyncLoading) {
    return
  }
  await customFieldsStore.resync(customField)
}

const customFieldsStore = useCustomFieldsStore()

onMounted(async () => {
  customFieldsStore.fetchCustomFields()
})
</script>

<style scoped lang="scss">
.setting__container {
  padding: 36px 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  .trash-icon {
    stroke: #667085;
  }
  .dropdown-icon {
    fill: #667085;
  }

  .setting__title {
    color: #212223;
    font-size: 18px;
    font-weight: 500;
  }

  .add-custom-field__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .setting__subtitle {
    display: flex;
    align-items: center;
    color: #667085;
    font-weight: 500;
  }

  .add-custom-field__button {
    padding: 4px 16px 4px 8px;
    transition: 0.05s ease;
    display: flex;

    background: #0070ff;
    border: 1px solid #0070ff;
    color: #fff;

    padding: 4px 16px;
    font-weight: 500;
    border-radius: 8px;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    .plus-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }

  .setting__body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex: 1;
    margin-top: 16px;

    .loader {
      align-self: center;
    }
  }
  .setting__item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 8px 0;
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
    &:last-child {
      border: none;
    }

    .setting__item-title {
      display: flex;
      gap: 6px;
      width: 100%;
      justify-content: space-between;

      .setting__item-text {
        display: flex;
        gap: 6px;
        align-items: center;
      }
    }

    .setting__remove-custom-field {
      display: flex;
      align-items: center;
      transition:
        visibility 0s,
        opacity 0.15s ease;
      cursor: pointer;
      border-radius: 4px;
      padding: 2px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }

    .setting__dropdown-section {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .resync__button {
      color: #0070ff;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      svg {
        stroke: #0070ff;
      }
    }
  }
}
</style>
