// stores/phoneFieldsStore.js
import { defineStore } from 'pinia'
import axiosInstance from '@/services/axiosService'
import { usePhoneStore } from './phone'

export const usePhoneFieldsStore = defineStore('phoneFields', {
  state: () => ({
    phoneFields: [],
    loading: false,
    error: null
  }),

  getters: {
    activePhoneFields: (state) => state.phoneFields.filter((field) => field.active),
    inactivePhoneFields: (state) => state.phoneFields.filter((field) => !field.active)
  },

  actions: {
    async fetchPhoneFields() {
      this.loading = true
      this.error = null
      try {
        const response = await axiosInstance.get(`/api/companies/phone-fields`)
        this.phoneFields = response.data
      } catch (err) {
        this.error = err.response?.data?.error || 'An error occurred while fetching phone fields.'
      } finally {
        this.loading = false
      }
    },

    async addPhoneField({ name, label, importSource }) {
      this.loading = true
      this.error = null
      const phoneStore = usePhoneStore()
      try {
        await axiosInstance.post(`/api/companies/phone-fields/add`, {
          name,
          label,
          importSource
        })
        await this.fetchPhoneFields()
        phoneStore.initContactList()
      } catch (err) {
        phoneStore.errorMessage =
          err.response?.data?.error || 'An error occurred while adding the phone field.'
        this.error = err.response?.data?.error || 'An error occurred while adding the phone field.'
      } finally {
        this.loading = false
      }
    },

    async deletePhoneField({ name, importSource }) {
      if (this.activePhoneFields.length < 2) {
        return
      }
      this.loading = true
      this.error = null
      const phoneStore = usePhoneStore()
      try {
        await axiosInstance.post(`/api/companies/phone-fields/delete`, {
          fieldName: name,
          importSource
        })
        await this.fetchPhoneFields()
        phoneStore.initContactList()
      } catch (err) {
        this.error =
          err.response?.data?.error || 'An error occurred while deleting the phone field.'
      } finally {
        this.loading = false
      }
    }
  }
})
