<template>
  <div
    class="prospect__call-log"
    :class="{
      'prospect__call-log--pinned': callLog.pinned,
      'prospect__call-log--active': callLog.onGoingCall
    }"
  >
    <div class="log__header">
      <div>
        <div v-if="callLog.onGoingCall" class="log__title">
          Call - Connected
          <div class="in-progress-badge">In progress</div>
        </div>
        <div class="log__title" v-else-if="isPipedrive">
          {{ callLog.subject }}
        </div>
        <div class="log__title" v-else>
          {{ `Call - ${buildCallDispositionValue(callLog)?.label}` }}
          <pinned-badge v-if="callLog.pinned" class="pinned-badge" />
        </div>
        <div class="log__phone-numbers" v-if="callLog.fromPhoneNumber && callLog.toPhoneNumber">
          <div class="log__phone-numbers-item">
            <div class="log__phone-numbers-key">From</div>
            <div class="log__phone-numbers-key">To</div>
          </div>
          <div class="log__phone-numbers-item">
            <div class="log__phone-numbers-value">
              {{ formatPhoneNumber(callLog.fromPhoneNumber) }}
              <span v-if="sessionStore.isUserPhoneNumber(callLog.fromPhoneNumber)">(you)</span>
            </div>
            <div class="log__phone-numbers-value" v-if="callLog.toPhoneNumber">
              {{ formatPhoneNumber(callLog.toPhoneNumber) }}
              <span v-if="sessionStore.isUserPhoneNumber(callLog.toPhoneNumber)">(you)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="log__date">{{ formatDate(callLog.date) }}</div>
    </div>
    <div class="log__body" v-if="!callLog.onGoingCall">
      <div
        class="log__body-item log__body-item--outcome"
        v-if="!isPipedrive || callLog.callDisposition"
      >
        <div class="log__body-item-key">Outcome</div>
        <dropdown
          class="log__body-item-value"
          :items="contactStore.callDispositionList[callLog.importSource]"
          :initial-value="buildCallDispositionValue(callLog)"
          @update:value="($events) => updateCallDisposition(callLog, $events)"
        />
      </div>
      <div v-if="!isPipedrive" class="log__body-item log__body-item--direction">
        <div class="log__body-item-key">Direction</div>
        <div class="log__body-item-value">{{ callLog.direction }}</div>
      </div>
      <div
        class="log__body-item log__body-item--duration"
        v-if="typeof callLog.duration === 'number'"
      >
        <div class="log__body-item-key">Duration</div>
        <div class="log__body-item-value">{{ formatCallDuration(callLog.duration) }}</div>
      </div>
    </div>
    <div class="recording" v-if="hasRecording">
      <audio :id="`player_${callLog.callSid}`" controls @play="loadRecording(callLog)">
        <source v-if="callLog.audioUrl" :src="callLog.audioUrl" type="audio/mp3" />
      </audio>
    </div>
    <call-log-transcription :call-log="callLog" />
    <Textarea
      v-model="callLogCopy.body"
      autoResize
      rows="2"
      @blur="saveLog(callLog.id, callLogCopy.body)"
      variant="filled"
    />
  </div>
</template>

<script setup>
import { formatDate } from '../helpers/date'
import Dropdown from './Dropdown.vue'
import CallLogTranscription from './CallLogTranscription.vue'
import PinnedBadge from './PinnedBadge.vue'
import Textarea from 'primevue/textarea'
import Plyr from 'plyr'
import { useSessionStore } from '@/stores/sessionStore'

import { ref, watch, computed, nextTick, onMounted } from 'vue'
import { useContactStore } from '../stores/contactStore'
import axiosInstance from '@/services/axiosService'
import { formatPhoneNumber } from '../helpers/phoneNumber'

const sessionStore = useSessionStore()

const hasRecording = computed(
  () =>
    !props.callLog.noRecording && props.callLog.direction === 'OUTBOUND' && props.callLog.callSid
)

const props = defineProps({
  callLog: {
    type: Object,
    required: true
  },
  prospectName: {
    required: true,
    type: String
  }
})

const isPipedrive = computed(() => props.callLog.importSource === 'pipedrive')

const contactStore = useContactStore()

const callLogCopy = ref([])

callLogCopy.value = JSON.parse(JSON.stringify(props.callLog))

let player

onMounted(() => {
  nextTick(() => {
    initializePlayer()
  })
})

watch(hasRecording, (newValue) => {
  nextTick(() => {
    if (newValue) {
      initializePlayer()
    }
  })
})

function initializePlayer() {
  player = new Plyr(`#player_${props.callLog.callSid}`, {
    controls: ['play-large', 'play', 'progress', 'duration', 'download', 'settings'],
    settings: ['speed']
  })

  player.on('error', (event) => {
    console.error('Plyr error:', event)
    event.stopPropagation() // Prevent the event from propagating further
  })

  // Add custom download button click event
  const downloadButton = player.elements.buttons.download
  if (downloadButton) {
    downloadButton.addEventListener('click', handleDownload)
  }
}

async function handleDownload(event) {
  event.preventDefault() // Prevent the default behavior of the button
  const callLog = props.callLog
  await loadRecordingIfNeeded(callLog)
  if (callLog.audioUrl) {
    downloadRecording(callLog.audioUrl)
  }
}

async function loadRecordingIfNeeded(callLog) {
  if (!callLog.audioUrl && !callLog.recordingLoading) {
    try {
      // Load the recording
      callLog.recordingLoading = true
      const response = await axiosInstance.get(`/api/call-logs/recording/${callLog.callSid}`, {
        responseType: 'blob'
      })

      if (response.data) {
        // Create a URL from the blob
        const url = URL.createObjectURL(response.data)
        callLog.audioUrl = url

        // Set Plyr source if not already set
        if (!player.source) {
          player.source = {
            type: 'audio',
            title: 'Recording',
            sources: [
              {
                src: callLog.audioUrl,
                type: 'audio/mpeg'
              }
            ]
          }
        }
      }
    } catch (error) {
      console.error('Failed to load recording:', error)
      callLog.noRecording = true
    } finally {
      callLog.recordingLoading = false
    }
  }
}

function downloadRecording(url) {
  console.log('props.prospectName', props.prospectName)
  const a = document.createElement('a')
  a.href = url
  a.download = `${props.prospectName}.mp3`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

async function loadRecording(call) {
  const callLog = props.callLog
  await loadRecordingIfNeeded(callLog)
  if (callLog.audioUrl) {
    player.play()
  }
}

watch(
  () => props.callLog,
  () => {
    callLogCopy.value = JSON.parse(JSON.stringify(props.callLog))
  },
  { deep: true }
)

function buildCallDispositionValue(log) {
  return contactStore.callDispositionList[log.importSource].find(
    (item) => item.id === log.callDisposition
  )
}

function updateCallDisposition(log, newValue) {
  emit('saveCallDisposition', log.id, newValue.id)
}

const emit = defineEmits(['saveLog', 'saveCallDisposition'])

function saveLog(id, newLog) {
  if (props.callLog.body !== newLog) {
    emit('saveLog', id, newLog)
  }
}

function formatCallDuration(durationInSeconds) {
  const minutes = Math.floor(durationInSeconds / 60)
  const seconds = durationInSeconds % 60

  const formattedMinutes = minutes.toString().padStart(1, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}
</script>

<style scoped lang="scss">
.prospect__call-log {
  display: flex;
  gap: 16px;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  &--pinned {
    border: 1px solid #ababab;
  }
  &--active {
    border: 1px solid #14ba6d;
  }
  .log__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .log__title {
      font-size: 14px;
      font-weight: 500;
      display: flex;

      .pinned-badge {
        margin-left: 12px;
      }
    }
    .log__date {
      font-size: 12px;
      color: #667085;
    }
  }

  .log__phone-numbers {
    display: flex;
    margin-top: 4px;
    gap: 6px;
    color: #667085;
    .log__phone-numbers-item {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      gap: 2px;

      .log__phone-numbers-key {
        font-weight: 500;
      }
      .log__phone-numbers-value {
      }
    }
  }

  .log__body {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }

  .log__body-item {
    display: flex;
    flex-direction: column;
    &--outcome {
      margin-right: 24px;
    }
    &--direction {
      margin-right: 24px;
    }

    .log__body-item-key {
      font-size: 12px;
      font-weight: 500;
      color: #667085;
      margin-bottom: 4px;
    }
    .log__body-item-value {
      font-size: 12px;
      color: #667085;
      display: flex;
      align-items: center;
      flex: 1;
    }
  }
}
.in-progress-badge {
  margin-left: 12px;
  display: flex;
  align-items: center;
  background-color: #14ba6d;
  color: #ffffff;
  border-radius: 4px;
  padding: 2px 4px;
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
}
</style>
