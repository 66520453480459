<template>
  <div>
    <div v-if="contact.doNotCall" @click="updateDoNotCall(false)" class="resume-calls__button">
      Enable calls
    </div>
    <div
      v-else-if="new Date(contact.snoozeUntil) > new Date()"
      class="remove-snooze__button"
      @click="snooze(null)"
    >
      Remove snooze
    </div>
    <div class="split-button" v-else ref="dropdown">
      <!-- Primary Button -->
      <button
        class="call__button"
        :disabled="!canCall || !phoneStore.isPhoneNumberValid(props.contact.phoneNumberFromModal)"
        @click="makeCall"
      >
        <PhoneIcon class="phone-icon" size="20" />
        <div v-if="!phoneStore.isDialingOrCalling">
          {{
            props.contact.doNotCall
              ? 'Calls disabled'
              : phoneStore.isPhoneNumberValid(props.contact.phoneNumberFromModal)
                ? `Call ${formatPhoneNumber(props.contact.phoneNumberFromModal)}`
                : 'Invalid number'
          }}
        </div>
        <div v-else>Call in progress</div>
      </button>

      <!-- Dropdown Toggle -->
      <button
        class="dropdown-toggle"
        @click="toggleDropdown"
        :disabled="!canCall || !phoneStore.isPhoneNumberValid(props.contact.phoneNumberFromModal)"
      >
        <ChevronDownIcon class="chevron-down-icon" size="20" />
      </button>

      <!-- Dropdown Menu -->
      <ul v-if="dropdownVisible" class="dropdown-menu">
        <li
          v-for="(action, index) in callButtonOptions"
          :key="index"
          @click="() => action.command(index)"
          class="dropdown__item"
          :class="{
            'dropdown__item--danger': action.id === 'do_not_call',
            separator: action.separator
          }"
        >
          <div class="dropdown__label">
            <NoPhoneIcon v-if="action.id === 'do_not_call'" size="20" style="stroke: #ff3e32" />
            <AlarmIcon v-if="action.id === 'snooze'" size="20" style="stroke: rgb(148, 163, 184)" />
            {{ action.label }}
          </div>
          <ChevronRightIcon v-if="action.id === 'snooze'" style="stroke: #94a3b8"
            >/</ChevronRightIcon
          >

          <!-- Submenu Trigger -->
          <div
            v-if="activeSubmenu === index && action.submenu"
            class="submenu snooze-section"
            @click.stop
          >
            <div class="snooze-title">Snooze calls to this contact until:</div>
            <div class="snooze-selector">
              <date-selector
                @update:value="updateSnoozeValue"
                :initial-value="initialSnoozeOption"
                default-option-label="3-business-days"
                :option-labels="optionLabels"
                columnLayout
              />

              <div class="snooze-selector__button" @click="snoozeCommand(snoozeDate)">
                <AlarmIcon size="20" style="stroke: #fff" />
                Snooze
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import NoPhoneIcon from './icons/NoPhoneIcon.vue'
import AlarmIcon from './icons/AlarmIcon.vue'
import DateSelector from './DateSelector.vue'
import ChevronRightIcon from './icons/ChevronRight.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import PhoneIcon from './icons/PhoneIcon.vue'
import { usePhoneStore } from '@/stores/phone'
import { computed, ref, watch } from 'vue'
import { useSessionStore } from '../stores/sessionStore'
import axiosInstance from '@/services/axiosService'
import { addBusinessDays, addMonths, addWeeks, format } from 'date-fns'
import { onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { formatPhoneNumber } from '@/helpers/phoneNumber'

const phoneStore = usePhoneStore()
const sessionStore = useSessionStore()

const props = defineProps({
  contact: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['snoozed', 'update:doNotCall'])

const dropdown = ref(null)

onClickOutside(dropdown, () => {
  closeMenu()
})

const activeSubmenu = ref(null)

function toggleSubmenu(index) {
  activeSubmenu.value = activeSubmenu.value === index ? null : index
}

function closeMenu() {
  activeSubmenu.value = false
  dropdownVisible.value = false
}

const canCall = computed(() => {
  if (validPhoneNumbers.length === 0) {
    return false
  }

  return !phoneStore.isDialingOrCalling
})

const callButtonOptions = ref([])
const validPhoneNumbers = ref([])

function setPhoneNumbers() {
  callButtonOptions.value = []
  validPhoneNumbers.value = []

  sessionStore.phoneNumberList.map((phoneField) => {
    const potentialPhoneNumber = props.contact.phoneNumbers.find(
      (phone) => phone.key === phoneField.id
    )?.value

    if (potentialPhoneNumber && phoneStore.isPhoneNumberValid(potentialPhoneNumber)) {
      callButtonOptions.value.push({
        label: `${formatPhoneNumber(potentialPhoneNumber)} (${phoneField.label})`,
        command: () => {
          props.contact.phoneNumberFromModal = potentialPhoneNumber
          closeMenu()
        }
      })
      validPhoneNumbers.value.push(potentialPhoneNumber)
    }
  })
  callButtonOptions.value.push({ separator: true })
  callButtonOptions.value.push({
    id: 'snooze',
    label: 'Snooze',
    submenu: true,
    command: (event) => {
      toggleSubmenu(event)
    }
  })
  callButtonOptions.value.push({
    id: 'do_not_call',
    label: 'Do not call this contact',
    command: () => {
      updateDoNotCall(true)
      closeMenu()
    }
  })

  if (phoneStore.isPhoneNumberValid(props.contact.phoneNumber)) {
    props.contact.phoneNumberFromModal = props.contact.phoneNumber
  } else {
    props.contact.phoneNumberFromModal = validPhoneNumbers.value[0]
  }
}

watch(
  () => props.contact.phoneNumbers,
  () => {
    setPhoneNumbers()
  },
  { deep: true }
)

async function updateDoNotCall(value) {
  try {
    await axiosInstance.put(`/api/prospects/${props.contact.id}/do-not-call`, {
      doNotCall: value
    })
    props.contact.doNotCall = value
    emit('update:doNotCall', value)
  } catch (error) {
    console.log('error while updating do not call', error)
  }
}

onMounted(() => {
  setPhoneNumbers()
  initSnoozeOption()
})

const snoozeDate = ref()
let initialSnoozeOption

function initSnoozeOption() {
  const today = new Date()
  initialSnoozeOption = addBusinessDays(today, 3)
  snoozeDate.value = initialSnoozeOption
}
function updateSnoozeValue(date) {
  snoozeDate.value = date
}

async function snoozeCommand(date) {
  snooze(date)
  closeMenu()
}

async function snooze(date) {
  try {
    let formattedDate
    if (date) {
      formattedDate = new Date(date).toISOString().split('T')[0]
    } else {
      formattedDate = null
    }

    await axiosInstance.put(`/api/prospects/${props.contact.id}/snooze`, {
      snoozeUntil: formattedDate
    })
    props.contact.snoozeUntil = formattedDate
    emit('snoozed', formattedDate)
  } catch (error) {
    console.log('error while snoozing', error)
  }
}

const optionLabels = computed(() => {
  const today = new Date()
  return {
    '3-business-days': `In 3 business days (${format(addBusinessDays(today, 3), 'EEEE')})`,
    '1-week': `In 1 week (${format(addWeeks(today, 1), 'MMMM d')})`,
    '2-weeks': `In 2 weeks (${format(addWeeks(today, 2), 'MMMM d')})`,
    '1-month': `In 1 month (${format(addMonths(today, 1), 'MMMM d')})`,
    '3-month': `In 3 months (${format(addMonths(today, 3), 'MMMM d')})`,
    '6-month': `In 6 months (${format(addMonths(today, 6), 'MMMM d')})`,
    custom: 'Custom date'
  }
})

function makeCall() {
  if (!canCall.value) {
    return
  }
  phoneStore.startCallFromModal(props.contact)
}

const dropdownVisible = ref(false)

const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value
}
</script>

<style lang="scss" scoped>
.split-button {
  display: flex;
  align-items: center;
  position: relative;
}

.call__button {
  padding: 0.5rem 0.75rem;
  border-radius: 6px 0 0 6px;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  .phone-icon {
    stroke: #fff;
  }
  &:hover {
    background-color: #006fe6;
    border-color: #006fe6;
  }
}

.call__button:disabled {
  color: #b8b8b8;
  background: #f6f6f6;
  user-select: none;
  border: 1px solid #f6f6f6;
  cursor: unset;
  .phone-icon {
    stroke: #b8b8b8;
  }
}

.dropdown-toggle {
  padding: 0.5rem;
  border-radius: 0 6px 6px 0;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  display: flex;
  .chevron-down-icon {
    stroke: #fff;
  }
  &:hover {
    background-color: #006fe6;
    border-color: #006fe6;
  }
  &:disabled {
    color: #b8b8b8;
    background: #f6f6f6;
    user-select: none;
    border: 1px solid #f6f6f6;
    cursor: unset;
    .chevron-down-icon {
      stroke: #b8b8b8;
    }
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  list-style: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  padding: 0.25rem;
  outline: 0 none;
  display: flex;
  flex-direction: column;
  gap: 2px;

  background: #fff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

.dropdown-menu .dropdown__item {
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  gap: 6px;

  color: #334155;
  border-radius: 4px;
  white-space: nowrap;
  transition:
    color 0.2s,
    background 0.2s;

  padding: 6px;

  user-select: none;
  &:hover {
    color: #1e293b;
    background-color: #f1f5f9;
  }
  &--danger {
    color: #ff3e32;
    &:hover {
      color: #ff3e32;
    }
  }

  .dropdown__label {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.dropdown-menu .separator {
  border-top: 1px solid #e2e8f0;
  padding: 0;
  border-radius: 0;
}

.submenu {
  position: absolute;
  left: 100%;
  top: 0;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  margin: 0;
  padding: 0.25rem;
  list-style: none;
  outline: 0 none;
  display: flex;
  flex-direction: column;
  gap: 2px;

  background: #fff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

.resume-calls__button {
  padding: 6px 16px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #0070ff;
  border: 1px solid #dbdbdb;
  background: #fff;
  gap: 7px;
}

.remove-snooze__button {
  padding: 6px 16px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff3e32;
  border: 1px solid #dbdbdb;
  gap: 7px;
}

.snooze-section {
  padding: 16px;
  display: flex;
  gap: 4px;
  flex-direction: column;

  .snooze-selector {
    display: flex;
    gap: 8px;
    flex-direction: column;

    .snooze-selector__button {
      padding: 8px 16px;
      font-weight: 500;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: #006fe6;
      cursor: pointer;
      color: #fff;
      gap: 7px;
      align-self: start;
    }
  }
}
</style>
