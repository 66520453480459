<template>
  <transition>
    <div class="phone-number-formatting" v-if="listStore.formatNumberModalOpened">
      <div class="overlay"></div>

      <div class="phone-number-formatting__modal" ref="phoneNumberFormattingModal">
        <div class="title">We found invalid phone numbers</div>
        <div class="warning-message">
          Some phone number formats in your imported list are incompatible with Minari (we use E164
          standard: +336xxxxxxxx). <br /><br />Below are our suggested corrections to ensure
          compatibility with Minari.
        </div>
        <div v-if="listStore.updatePhoneNumbersLoading" class="phone__loader">
          <Loader></Loader>
          <div>This may take a few minutes, if you have hundreds of phone numbers to update.</div>
        </div>
        <div class="phone__list-container" v-else>
          <div class="phone__list">
            <div class="phone__text phone__text--title" style="width: 220px">Contact</div>
            <div class="phone__text phone__text--title" style="width: 130px">Invalid</div>
            <div class="arrow"></div>
            <div class="phone__text phone__text--title" style="width: 130px">Autocorrect</div>
          </div>
          <div
            v-for="phoneNumber in listStore.invalidPhoneNumbers"
            :key="phoneNumber.original"
            class="phone__list"
          >
            <div class="phone__text" style="width: 220px">
              {{ phoneNumber.prospectName }}
            </div>
            <div class="phone__text" style="width: 130px">
              {{ phoneNumber.original }}
            </div>
            <div class="arrow">-></div>
            <div class="phone__text" style="width: 130px">
              {{ phoneNumber.suggested }}
            </div>
          </div>
        </div>
        <div class="footer">
          <Button
            label="Do not fix format"
            icon="pi pi-times"
            @click="closeDropdown"
            severity="secondary"
            :disabled="listStore.updatePhoneNumbersLoading"
          />

          <Button
            :label="`Update ${listStore.invalidPhoneNumbers.length} phone ${
              listStore.invalidPhoneNumbers.length === 1 ? 'number' : 'numbers'
            }`"
            icon="pi pi-check"
            @click="updatePhoneNumbers"
            :disabled="listStore.updatePhoneNumbersLoading"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import Button from 'primevue/button'
import Message from 'primevue/message'
import { nextTick, onMounted, ref, computed, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import Loader from '../components/Loader.vue'

const toast = useToast()

const emit = defineEmits(['select:value', 'close'])

import { useListStore } from '@/stores/listStore'

const listStore = useListStore()

async function updatePhoneNumbers() {
  await listStore.updatePhoneNumbersBatch()
  toast.add({
    severity: 'success',
    summary: 'Phone numbers updated successfully',
    life: 3000
  })
}

function closeDropdown() {
  emit('close')
  listStore.formatNumberModalOpened = false
}

const phoneNumberFormattingModal = ref(null)

onClickOutside(phoneNumberFormattingModal, () => {
  // closeDropdown()
})
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.phone-number-formatting {
  .phone-number-formatting__modal {
    position: absolute;
    top: 90px;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    flex-direction: column;

    overflow: hidden;
    z-index: 4;

    box-shadow:
      rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
      rgba(15, 15, 15, 0.1) 0px 5px 10px,
      rgba(15, 15, 15, 0.2) 0px 15px 40px;
    border-radius: 12px;
    background-color: #ffffff;

    width: 650px;
    max-height: calc(-180px + 100vh);

    line-height: normal;

    padding: 36px 60px;
    gap: 16px;

    .warning-message {
      background: color-mix(in srgb, #fefce8, transparent 5%);
      outline-color: #fef08a;
      color: #ca8a04;
      border-radius: 6px;
      outline-width: 1px;
      outline-style: solid;
      border: 0px solid #e5e7eb;
      padding: 0.5rem 0.75rem;
    }

    .title {
      color: #212223;
      font-size: 18px;
      font-weight: 500;
    }

    .subtitle {
      color: #667085;
      font-weight: 500;
    }

    .phone__list-container {
      flex: 1;
      gap: 8px;
      display: flex;
      flex-direction: column;
      overflow: scroll;
    }
    .phone__loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .phone__list {
      display: flex;
      .phone__text {
        text-wrap: wrap;

        &--title {
          font-weight: 500;
        }
      }
      .arrow {
        text-align: center;
        width: 50px;
      }
    }
    .footer {
      align-self: flex-end;
      display: flex;
      gap: 16px;
      margin-top: 24px;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(15, 15, 15, 0.6);
  }
}
</style>
