<template>
  <div class="setting__container">
    <div class="setting__title">Phone fields</div>

    <div class="add-phone-field__container">
      <SelectDropdown :items="phoneFieldsStore.inactivePhoneFields" @update:value="onValueUpdate">
        <template #button="{ toggleDropdown, isOpen }">
          <div class="add-phone-field__button" @click="toggleDropdown">
            <div class="plus-icon">
              <PlusIcon color="#fff" size="20" />
            </div>
            <div class="add-phone-field__button-text">Add a phone field</div>
          </div>
        </template>
      </SelectDropdown>
      <div class="setting__subtitle">
        <InfoIcon class="trash-icon" size="20" />Refresh the list to see new phone fields
      </div>
    </div>
    <div class="setting__body">
      <div v-if="phoneFieldsStore.loading && !phoneFieldsStore.resyncLoading" class="loader">
        <Loader :size="20"></Loader>
      </div>
      <div
        v-else
        v-for="phoneField in phoneFieldsStore.activePhoneFields"
        :key="phoneField.name"
        class="setting__item"
      >
        <div class="setting__item-title">
          <div class="setting__item-text">
            {{ phoneField.label }}
          </div>
          <div
            class="setting__remove-phone-field"
            :class="{
              'setting__remove-phone-field--deleted': phoneFieldsStore.activePhoneFields.length < 2
            }"
            @click="removePhoneField(phoneField)"
          >
            <TrashIcon class="trash-icon" size="20" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { usePhoneFieldsStore } from '@/stores/phoneFieldsStore'
import PlusIcon from '../icons/PlusIcon.vue'
import TrashIcon from '../icons/TrashIcon.vue'
import InfoIcon from '../icons/InfoIcon.vue'
import SelectDropdown from '../SelectDropdown.vue'
import Loader from '../Loader.vue'

async function onValueUpdate(phoneField) {
  await phoneFieldsStore.addPhoneField(phoneField)
}

async function removePhoneField(phoneField) {
  await phoneFieldsStore.deletePhoneField(phoneField)
}

const phoneFieldsStore = usePhoneFieldsStore()

onMounted(async () => {
  phoneFieldsStore.fetchPhoneFields()
})
</script>

<style scoped lang="scss">
.setting__container {
  padding: 36px 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  .trash-icon {
    stroke: #667085;
  }

  .setting__title {
    color: #212223;
    font-size: 18px;
    font-weight: 500;
  }

  .add-phone-field__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .setting__subtitle {
    display: flex;
    align-items: center;
    color: #667085;
    font-weight: 500;
  }

  .add-phone-field__button {
    padding: 4px 16px 4px 8px;
    transition: 0.05s ease;
    display: flex;

    background: #0070ff;
    border: 1px solid #0070ff;
    color: #fff;

    padding: 4px 16px;
    font-weight: 500;
    border-radius: 8px;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    .plus-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }

  .setting__body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex: 1;
    margin-top: 16px;

    .loader {
      align-self: center;
    }
  }
  .setting__item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 8px 0;
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
    &:last-child {
      border: none;
    }

    .setting__item-title {
      display: flex;
      gap: 6px;
      width: 100%;
      justify-content: space-between;
    }

    .setting__remove-phone-field {
      display: flex;
      align-items: center;
      transition:
        visibility 0s,
        opacity 0.15s ease;

      cursor: pointer;
      border-radius: 4px;
      padding: 2px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }

      &--deleted {
        cursor: not-allowed;
        .trash-icon {
          stroke: #c7c8ca;
        }
        &:hover {
          background-color: unset;
        }
      }
    }
  }
}
</style>
