// stores/mappedFieldsStore.js
import { defineStore } from 'pinia'
import axiosInstance from '@/services/axiosService'
import { usePhoneStore } from './phone'

export const useMappedFieldsStore = defineStore('mappedFields', {
  state: () => ({
    crmFields: [],
    mappedFields: [],
    loading: false,
    error: null
  }),

  actions: {
    async fetchMappedFields() {
      this.loading = true
      this.error = null
      try {
        const response = await axiosInstance.get(`/api/companies/mapped-fields`)
        this.crmFields = response.data.fields
        this.mappedFields = response.data.mappedFields
      } catch (err) {
        this.error = err.response?.data?.error || 'An error occurred while fetching mapped fields.'
      } finally {
        this.loading = false
      }
    },

    async updateMappedField({ internalFieldName, importSource }, { value }) {
      this.loading = true
      this.error = null
      const phoneStore = usePhoneStore()
      console.log('importSource', importSource)
      try {
        await axiosInstance.post(`/api/companies/mapped-fields/update`, {
          newValue: value,
          internalFieldName,
          importSource
        })
        await this.fetchMappedFields()
      } catch (err) {
        phoneStore.errorMessage =
          err.response?.data?.error || 'An error occurred while updating the mapped field.'
        this.error =
          err.response?.data?.error || 'An error occurred while updating the mapped field.'
      } finally {
        this.loading = false
      }
    }
  }
})
