import { defineStore } from 'pinia'
import axios from '../services/axiosService'

const LOG_TYPE_MAPPING = {
  dials: 'Dials',
  connects: 'Connects',
  callbacks: 'Callbacks',
  callbackConnects: 'Callback connects',
  conversations: 'Conversations',
  meetingBooked: 'Meeting booked'
}

export const useAnalyticsStore = defineStore('analyticsStore', {
  state: () => ({
    userName: '',
    logType: '',
    startDate: null,
    endDate: null,
    optionName: 'this_month_so_far',
    selectedUsers: [],
    selectedSessions: [],
    allPeriodStartDate: null,
    minDuration: 0,
    statuses: [],
    callDirection: ''
  }),
  getters: {
    getLogTypeLabel: (state) => LOG_TYPE_MAPPING[state.logType]
  },
  actions: {
    setUserName(userName) {
      this.userName = userName
    },
    setLogType(logType) {
      this.logType = logType
    },
    resetStore() {
      this.$reset() // Pinia has a built-in reset method
    }
  }
})
