<template>
  <div class="dropdown" ref="root">
    <div class="voicemail__text">Voicemail</div>
    <div
      @click="toggleDropdown"
      class="dropdown__button"
      :class="{
        'dropdown__button--enable': !disable,
        'dropdown__button--disable': disable
      }"
    >
      <div class="dropdown__buttonGroup">
        <div class="green-dot" v-if="voicemailStore.voicemailSelected"></div>
        <div class="grey-dot" v-else></div>
        <VoicemailIcon class="voicemail-icon" size="20" />
        <div class="dropdown__buttonText">
          {{ voicemailStore.voicemailSelected?.name || 'Off' }}
        </div>
      </div>
      <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" size="12" />
      <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
    </div>
    <div v-if="isOpen" class="dropdown--open" @click.stop="">
      <div class="dropdown__header">
        <div class="dropdown__item" @click="selectAudio(null)">
          <div
            class="dropdown__item-group"
            :class="{
              'dropdown__item--selected': voicemailStore.voicemailSelected === null,
              'dropdown__item--unselected': voicemailStore.voicemailSelected !== null
            }"
          >
            <div v-if="voicemailStore.voicemailSelected === null" class="checkmark-icon">
              <CheckmarkIcon size="21" />
            </div>
            Off
          </div>
        </div>
      </div>
      <div class="dropdown__main" v-if="voicemailStore.audioFiles.files.length">
        <div
          class="dropdown__item"
          v-for="audioFile in voicemailStore.audioFiles.files"
          :key="audioFile.id"
          @click="selectAudio(audioFile)"
        >
          <div
            class="dropdown__item-group"
            :class="{
              'dropdown__item--selected':
                audioFile.messageId === voicemailStore.voicemailSelected?.messageId,
              'dropdown__item--unselected':
                audioFile.messageId !== voicemailStore.voicemailSelected?.messageId
            }"
          >
            <div
              v-if="audioFile.messageId === voicemailStore.voicemailSelected?.messageId"
              class="checkmark-icon"
            >
              <CheckmarkIcon color="#344054" size="21" />
            </div>
            <div class="dropdown__item-text">
              {{ audioFile.name }}
            </div>
          </div>
          <div class="dropdown__item-group" @click.stop="playAudioFile(audioFile)">
            <div
              class="play-audio-icon"
              :class="{ isCurrentlyPlaying: isPlaying === audioFile.messageId }"
            >
              <PlayAudioIcon color="#707070" size="20" />
            </div>
            <div class="trash-icon" @click.stop="deleteAudioFile(audioFile.messageId)">
              <TrashIcon class="trash-icon-svg" size="20" />
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown__footer">
        <div class="dropdown__footer--step1" v-if="step === 1" @click="step = 2">
          <div class="footer__button">
            <div class="plus-icon">
              <PlusIcon color="#828282" size="20" />
            </div>
            <div class="footer__button-text">Record a voicemail</div>
          </div>
        </div>
        <div v-else-if="step === 2" class="dropdown__footer--step2" @click="startRecording">
          <div class="microphone-icon-section">
            <MicrophoneIcon class="microphone-icon" size="20" />
          </div>
        </div>
        <div v-else-if="step === 3" class="dropdown__footer--step3">
          <div class="footer__microphone-group">
            <div class="red-dot"></div>
            <div class="microphone-icon--static">
              <MicrophoneIcon class="microphone-icon" size="20" />
            </div>
          </div>
          <div class="footer__stop-group" @click="stopRecording">
            <div class="square-icon">
              <SquareIcon size="14" />
            </div>
            <div class="timer-text">
              {{ formatDuration(recordingDuration) }}
            </div>
          </div>
          <div class="footer__delete-group" @click="stopAndDelete">
            <TrashIcon class="trash-icon-svg" size="20" />
          </div>
        </div>
        <div v-else-if="step === 4" class="dropdown__footer--step4">
          <input class="voicemail__name-input" type="text" v-model="voicemailName" ref="input" />
          <div class="voicemail__audio-group" v-if="currentRecording">
            <AudioPlayer
              :audioSource="currentRecording.url"
              :duration="currentRecording.duration"
              ref="audioPlayerRef"
            />
            <div class="voicemail__trash-icon" @click="deleteCurrentRecording">
              <TrashIcon class="trash-icon-svg" size="20" />
            </div>
          </div>
        </div>

        <form
          v-if="step === 4"
          class="dropdown__footer-cta"
          @submit.prevent="prepareAndSaveRecording"
          enctype="multipart/form-data"
        >
          <button class="footer__save-button" type="submit">
            <div class="save-icon">
              <SaveIcon color="#fff" size="20" />
            </div>
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import AudioPlayer from './AudioPlayer.vue'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'
import PlayAudioIcon from './icons/PlayAudioIcon.vue'
import TrashIcon from './icons/TrashIcon.vue'
import PlusIcon from './icons/PlusIcon.vue'
import SquareIcon from './icons/SquareIcon.vue'
import MicrophoneIcon from './icons/MicrophoneIcon.vue'
import SaveIcon from './icons/SaveIcon.vue'
import VoicemailIcon from './icons/VoicemailIcon.vue'

import { useVoicemailStore } from '@/stores/voicemail'
import { useSessionStore } from '@/stores/sessionStore'
import { saveVoicemail, deleteVoicemail } from '../services/voicemailService'
const voicemailStore = useVoicemailStore()
const sessionStore = useSessionStore()

import { onMounted, ref, onUnmounted, nextTick } from 'vue'

const props = defineProps({
  disable: {
    required: true,
    type: Boolean
  }
})

let recordingName = ''
const currentRecording = ref(null)
const recordingDuration = ref(0)

const step = ref(1)

const voicemailName = ref('')
const isPlaying = ref(false)
const isOpen = ref(false)

let intervalId

onMounted(async () => {
  await voicemailStore.fetchUserVoicemails()

  const expirationTime = voicemailStore.audioFiles.expiresIn
  const refreshInterval = expirationTime * 1000 * 0.9
  // Function to refresh the voicemails
  const refreshVoicemails = async () => {
    console.log('refreshing voicemail urls')
    await voicemailStore.fetchUserVoicemails()
    voicemailStore.setVoicemail(
      voicemailStore.audioFiles.files.find(
        (file) => file.messageId === voicemailStore.voicemailSelected?.messageId
      )
    )
  }

  // Set up an interval to refresh the voicemails
  intervalId = setInterval(refreshVoicemails, refreshInterval)
})

// Clean up: clear the interval when the component is unmounted
onUnmounted(() => {
  clearInterval(intervalId)
})

const root = ref(null)
onClickOutside(root, () => {
  closeDropdown()
})

function toggleDropdown() {
  if (props.disable) {
    return
  }
  isOpen.value = !isOpen.value
}

function closeDropdown() {
  isOpen.value = false
  stopAndDelete()
  step.value = 1
}
function selectAudio(message) {
  voicemailStore.voicemailSelected = message
  voicemailStore.setVoicemail(message)
  isOpen.value = false
  sessionStore.updateSessionSettings({ audioMessageId: message?.messageId || null })
}
let mediaRecorder
let timerInterval
let audioChunks = []

function stopAndDelete() {
  if (mediaRecorder) {
    clearInterval(timerInterval)
    mediaRecorder = null
    audioChunks = []
  }
  deleteCurrentRecording()
}
function formatDuration(seconds) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

let currentAudioPlayer = null

function playAudioFile(file) {
  if (currentAudioPlayer && !currentAudioPlayer.paused) {
    currentAudioPlayer.pause()
    currentAudioPlayer.currentTime = 0 // Reset playback position
  }

  const audioPlayer = new Audio(file.url)
  currentAudioPlayer = audioPlayer

  audioPlayer
    .play()
    .then(() => {
      // This promise resolves when playback starts
      isPlaying.value = file.messageId
    })
    .catch((error) => {
      console.error('Error playing audio:', error)
    })

  audioPlayer.addEventListener('ended', () => {
    isPlaying.value = false
  })
}

async function deleteAudioFile(id) {
  if (id === voicemailStore.voicemailSelected?.messageId) {
    voicemailStore.setVoicemail(null)
    await sessionStore.updateSessionSettings({ audioMessageId: null })
  }
  await deleteVoicemail(id)
  await voicemailStore.fetchUserVoicemails()
}
function startRecording() {
  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream) => {
      step.value = 3
      const timer = Date.now()
      mediaRecorder = new MediaRecorder(stream)

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunks.push(e.data)
        }
      }

      mediaRecorder.onstop = () => {
        const duration = (Date.now() - timer) / 1000
        const blob = new Blob(audioChunks, { type: 'audio/wav' })
        const recordingUrl = URL.createObjectURL(blob)
        currentRecording.value = {
          url: recordingUrl,
          name: recordingName || 'Unnamed Recording',
          duration
        }

        stream.getTracks().forEach((track) => track.stop())
        audioChunks = []
      }

      mediaRecorder.start()
      recordingDuration.value = 0
      timerInterval = setInterval(() => {
        recordingDuration.value += 1
      }, 1000)
    })
    .catch((err) => {
      console.error('Error accessing microphone:', err)
    })
}

const input = ref(null)
function stopRecording() {
  if (mediaRecorder) {
    step.value = 4
    mediaRecorder.stop()
    clearInterval(timerInterval)
    voicemailName.value = 'Voicemail 1'
    nextTick(() => {
      input.value.select()
    })
  }
}
const audioPlayerRef = ref(null)
function playCurrentRecording() {
  audioPlayerRef.play()
}
function deleteCurrentRecording() {
  currentRecording.value = null
  recordingName = ''
  recordingDuration.value = 0
  step.value = 2
}

function prepareAndSaveRecording() {
  if (currentRecording.value) {
    // Convert Blob URL to Blob data
    fetch(currentRecording.value.url)
      .then((response) => response.blob())
      .then((blob) => {
        const formData = new FormData()
        formData.append('name', voicemailName.value)
        formData.append('audio-file', blob)

        return saveVoicemail(formData)
      })
      .then(async () => {
        step.value = 1
        voicemailStore.fetchUserVoicemails()
      })
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
  user-select: none;

  .voicemail__text {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 4px;
  }
}

.dropdown .dropdown__button {
  padding: 7px 16px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-weight: 500;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 14px;
  transition: opacity 0.4s ease;
}

.dropdown .dropdown__button--enable {
  color: #344054;
  background: #fff;
}
.dropdown .dropdown__button--enable:hover {
  border: 1px solid #c2c2c2;
  cursor: pointer;
}
.dropdown .dropdown__button--disable {
  color: #b8b8b8;
  background: #f6f6f6;
  user-select: none;
}

.voicemail-icon {
  stroke: #344054;
}
.dropdown__button--disable .voicemail-icon {
  stroke: #b8b8b8;
}

.dropdown__buttonGroup {
  display: flex;
  align-items: center;
}

.dropdown__buttonText {
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 110px;
  font-size: 14px;
}

.dropdown--open {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #344054;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  min-width: 250px;
  overflow: visible;
  background: #fff;
  z-index: 2;
}
.dropdown__header,
.dropdown__main {
  border-bottom: 1px solid #d0d5dd;
}
.dropdown__main,
.dropdown__header,
.dropdown__footer--step1 {
  padding: 4px;
}
.dropdown__item-group {
  display: flex;
  align-items: center;
}
.dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.05s ease;
  padding: 4px 4px 4px 0;
}
.dropdown__item-text {
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.play-audio-icon {
  padding: 2px;
  border-radius: 4px;
  display: flex;
  margin-right: 4px;

  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.15s ease;
  cursor: pointer;
}
.dropdown__item:hover .play-audio-icon,
.dropdown__item:hover .trash-icon {
  visibility: visible;
  opacity: 1;
}
.dropdown__item:hover,
.footer__button:hover {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.07);
  opacity: 1;
  cursor: pointer;
}
.checkmark-icon {
  display: flex;
}
.dropdown__item--selected {
  padding-left: 0;
  font-weight: 600;
}
.dropdown__item--unselected {
  padding-left: 21px;
}
.play-audio-icon:hover {
  background-color: #d9d9d9;
}
.play-audio-icon.isCurrentlyPlaying {
  visibility: visible;
  opacity: 1;
}
.dropdown__footer--step1 {
  display: flex;
}
.dropdown__footer--step2 {
  display: flex;
  justify-content: center;
  padding: 12px;
}
.microphone-icon-section,
.microphone-icon--static {
  display: flex;
  border: solid 1px #dbdbdb;
  border-radius: 24px;
  padding: 7px;
}
.microphone-icon {
  stroke: #344054;
}
.microphone-icon-section:hover {
  border: solid 1px #c2c2c2;
  cursor: pointer;
}
.dropdown__footer--step3 {
  display: flex;
  justify-content: space-between;
  padding: 11px 12px;
}
.red-dot {
  height: 6px;
  width: 6px;
  background-color: #ff3e32;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.green-dot {
  height: 6px;
  width: 6px;
  background-color: #1dde84;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.grey-dot {
  height: 6px;
  width: 6px;
  background-color: #d0d5dd;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.square-icon {
  display: flex;
  stroke: #ff3e32;
}
.square-icon {
  display: flex;
  align-items: center;
  margin-right: 6px;
}
.footer__stop-group {
  border: 1px solid #dbdbdb;
  padding: 7px 10px;
  border-radius: 30px;
  display: flex;
  width: 72px;
}
.footer__stop-group:hover {
  border: solid 1px #c2c2c2;
  cursor: pointer;
}
.timer-text {
  color: #787878;
}
.footer__delete-group,
.voicemail__trash-icon {
  display: flex;
  align-items: center;
}
.footer__delete-group:hover,
.voicemail__trash-icon:hover {
  cursor: pointer;
}
.trash-icon {
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.15s ease;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
}
.trash-icon:hover {
  background-color: #d9d9d9;
}
.footer__microphone-group {
  display: flex;
  align-items: center;
}
.plus-icon {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.footer__button-text {
  color: #828282;
}
.footer__button {
  padding: 4px 16px 4px 8px;
  transition: 0.05s ease;
  display: flex;
  flex-grow: 1;
}
.voicemail__audio-group {
  display: flex;
}
.dropdown__footer--step4 {
  padding: 12px;
}
.voicemail__name-input {
  width: 100%;
  margin-bottom: 12px;
}
.footer__delete-group,
.voicemail__trash-icon {
  margin-left: 10px;
}
.voicemail__name-input {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  color: #474747;
  font-weight: 500;
  padding: 5px;
}
.voicemail__name-input:focus {
  border: 1px solid #0070ff;
  outline: 2px solid #cbe2ff;
}
.dropdown__footer-cta {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #0070ff;
  border-radius: 0px 0px 8px 8px;
  position: relative;
  left: -1px;
  top: 1px;
  width: calc(100% + 2px);
  font-weight: 500;
}
.save-icon {
  margin-right: 7px;
}
.footer__save-button {
  width: 100%;
  background: #0070ff;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  color: #fff;
  font-weight: 500;
  border-radius: 0px 0px 8px 8px;
  align-items: center;
  padding: 10px 0;
}
.footer__save-button:hover {
  cursor: pointer;
}
.dropdown__button-svg {
  pointer-events: none;
  stroke: #D0D5DD;
}
.trash-icon-svg {
  stroke: #707070;
}
</style>
@/stores/voicemail
