<template>
  <div
    class="call__transcription"
    v-if="callLog.transcription || callLog.recordingStatus === 'loading'"
  >
    <div
      class="transcription__loader"
      v-if="callLog.transcription === 'loading' || callLog.recordingStatus === 'loading'"
    >
      <Loader borderColor="#bfdbfe" :size="20" />
      Transcription in progress...
    </div>
    <div v-else class="transcription__body">
      <div class="call__summary">
        <div class="title">
          Call summary
          <beta-badge />
        </div>
        <div></div>
        <div style="white-space: pre-line">
          {{ callLog.transcription.summary }}
        </div>
      </div>
      <div class="toggle-transcription__title">
        <div class="toggle-transcription" @click="toggleFullTranscription">
          {{ showFullTranscription ? 'Hide' : 'Show' }} full transcription
          <ChevronDownIcon class="dropdown__button-svg" v-if="!showFullTranscription" size="14" />
          <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="14" />
        </div>
        <beta-badge />
      </div>
      <div v-if="showFullTranscription" class="full__transcription">
        <div v-for="(line, index) in callLog.transcription.fullTranscription" :key="index">
          <strong>{{ line.speaker }}</strong> | {{ line.timestamp }}
          <div class="transcription__line">{{ line.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Loader from './Loader.vue'
import BetaBadge from './BetaBadge.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'

import { ref, watch, computed, nextTick, onMounted } from 'vue'

const showFullTranscription = ref(false)

function toggleFullTranscription() {
  showFullTranscription.value = !showFullTranscription.value
}

const props = defineProps({
  callLog: {
    type: Object,
    required: true
  }
})
</script>

<style scoped lang="scss">
.call__transcription {
  .transcription__body {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .transcription__loader {
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 12px;
    background: color-mix(in srgb, #eff6ff, transparent 5%);
    outline-color: #bfdbfe;
    color: #2563eb;
    border-radius: 6px;
    outline-width: 1px;
    outline-style: solid;
    padding: 0.5rem 0.75rem;
  }

  .toggle-transcription__title {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .full__transcription {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .transcription__line {
    margin-top: 6px;
  }
  .toggle-transcription {
    align-self: start;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.05s ease;
    color: #828282;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: #dbdbdb;
    user-select: none;

    .dropdown__button-svg {
      pointer-events: none;
      stroke: #d0d5dd;
    }
    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);
      opacity: 1;
      cursor: pointer;
    }
  }

  .call__summary {
    background: color-mix(in srgb, #eff6ff, transparent 5%);
    outline-color: #bfdbfe;
    color: #2563eb;
    border-radius: 6px;
    outline-width: 1px;
    outline-style: solid;
    border: 0px solid #e5e7eb;
    padding: 0.5rem 0.75rem;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 600;
      display: flex;
      gap: 6px;
    }
  }
}
</style>
