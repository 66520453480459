<script setup>
import { useAuthStore } from '@/stores/auth'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from './services/axiosService'
import salesforceLogo from '@/assets/salesforce-logo-2.svg'
import hubspotLogo from '@/assets/hubspot-logo-2.svg'
import pipedriveLogo from '@/assets/pipedrive-logo-2.svg'
import logoMinari from '@/assets/logo-minari.png'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const contentLoaded = ref(false)

function onLogoLoad() {
  contentLoaded.value = true
}

async function fetchUserInfo() {
  try {
    const response = await axios.get('/api/user-info')
    return response.data.userId
  } catch (error) {
    console.error('Error fetching user info:', error)
    // Handle errors (e.g., redirect to login page)
  }
}

const showErrorMessage = ref(false)
const errorMessage = ref('')

onMounted(async () => {
  if (route.query.success === 'true') {
    // Update the store to reflect authenticated state
    const userId = await fetchUserInfo()
    console.log('userId', userId)
    if (userId) {
      authStore.setAuthStatus(userId)
      router.push({ name: 'main' })
    } else {
      console.error('error getting the user id')
      // Handle the case where the user info couldn't be fetched
      // This might include redirecting to a login page or showing an error message
    }

    // Redirect to the main app or a protected route
    router.push({ name: 'main' })
  } else if (route.query.error === 'no_invitation') {
    errorMessage.value = 'You need an invitation to join Minari, please book a call first'
    showErrorMessage.value = true
  } else if (route.query.error === 'multiple_hubspot_accounts') {
    errorMessage.value = 'Your Minari account is already linked to another Hubspot account'
    showErrorMessage.value = true
  }
})

import { useSalesforceStore } from './stores/salesforceStore'
import { useHubspotStore } from './stores/hubspotStore'
import { usePipedriveStore } from './stores/pipedriveStore'

const salesforceStore = useSalesforceStore()
const hubspotStore = useHubspotStore()
const pipedriveStore = usePipedriveStore()

async function login() {
  const invitationToken = route.query.invitationToken || ''
  const authUrl = await salesforceStore.getAuthUrl(invitationToken)

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get Salesforce authorization URL')
    // Handle the error case
  }
}

async function hubspotLogin() {
  const invitationToken = route.query.invitationToken || ''

  const authUrl = await hubspotStore.getAuthUrl(invitationToken)

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get Hubspot authorization URL')
    // Handle the error case
  }
}

async function pipedriveLogin() {
  const invitationToken = route.query.invitationToken || ''
  const authUrl = await pipedriveStore.getAuthUrl(invitationToken)

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get Pipedrive authorization URL')
    // Handle the error case
  }
}
</script>

<template>
  <div class="login__page" :class="{ 'content-loaded': contentLoaded }">
    <div class="login__page--header">
      <img :src="logoMinari" alt="logo Minari" @load="onLogoLoad" />
    </div>
    <div class="login__section">
      <div class="login__welcome">
        <h1>Welcome back!</h1>
        <p>Login to Minari</p>
      </div>
      <div class="login__container">
        <div class="login__container--button" @click="login">
          <img class="login__container--logo" :src="salesforceLogo" alt="salesforce logo" />
          <div></div>
          Sign in with Salesforce
        </div>
        <div class="login__container--button" @click="hubspotLogin">
          <img class="login__container--logo" :src="hubspotLogo" alt="hubspot logo" />
          <div></div>
          Sign in with Hubspot
        </div>
        <div class="login__container--button" @click="pipedriveLogin">
          <img class="login__container--logo" :src="pipedriveLogo" alt="pipedrive logo" />
          <div></div>
          Sign in with Pipedrive
        </div>
      </div>
      <div class="login__frieze"></div>
      <div class="contact__container">
        <div v-if="showErrorMessage" class="login__error-message">
          {{ errorMessage }}
        </div>
        <div v-else>Don't have an account?</div>
        <a href="https://minari.ai/#contact" class="contact__container--button"
          >Book a call with our Sales Team</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login__page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: linear-gradient(180deg, #fafafa 10%, #d5fcfe 100%);
  &.content-loaded {
    opacity: 1;
  }
  &--header {
    height: 15%;
    min-height: 80px;
    display: flex;
    align-items: center;
    img {
      height: 32px;
    }
  }
}
.login__section {
  width: min(92.14vw, 680px);
  max-width: 90%;
  background-image: url('@/assets/login-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 120px;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  @media (max-width: 1536px) {
    gap: 24px;
    padding: 3rem 0;
  }
  @media (max-width: 600px) {
    aspect-ratio: initial;
    border-radius: 60px;
  }
}
.login__welcome {
  font-weight: 600;
  text-align: center;
  h1 {
    font-size: 44px;
    margin: 0;
    margin-bottom: 16px;
    line-height: 50px;
    font-family: $font-headline;
    @media (max-width: 600px) {
      font-size: 34px;
    }
  }
  p {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    font-family: $font-body;
    color: #d0e1e9;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
}
.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  &--button {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 20px 24px;
    border-radius: 100px;
    border-top: 1px solid #5c6668;
    border-bottom: 1px solid #5c6668;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background: rgba(80, 101, 102, 0.36);
    font-size: 16px;
    font-weight: 700;
    font-family: $font-body;
    width: 80%;
    max-width: 300px;
    &:hover {
      cursor: pointer;
      border: 1px solid #c2c2c2;
    }
    @media (max-width: 600px) {
      font-size: 15px;
      padding: 16px 20px;
    }
  }
  &--logo {
    width: 40px;
    margin-right: 20px;
    object-fit: cover;
    height: 100%;
    box-shadow: 0px 6.67px 40px -8px #9dcad099;
    border-radius: 13px;
  }
}

.login__frieze {
  width: calc(70% - 60px);
  height: 12px;
  background-image: url('@/assets/frieze-icon.svg');
  background-repeat: repeat-x;
  background-size: 30px 100%;
  @media (max-width: 600px) {
    width: 270px;
  }
}

.login__error-message {
  font-size: 18px;
  font-weight: 600;
  font-family: $font-body;
  color: #ff8a00;
}

.contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
  > *:first-child {
    font-size: 18px;
    font-weight: 600;
    font-family: $font-body;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
  &--button {
    background: linear-gradient(180deg, #ffbd71 0%, #ff8a00 100%);
    box-shadow:
      4px 10px 20px 0px rgba(255, 158, 42, 0.2),
      inset 0px 6px 24px 0px #ffd8a5;
    border-radius: 100px;
    padding: 24px;
    font-family: $font-body;
    font-weight: 700;
    font-size: 16px;
    line-height: 21.86px;
    color: #372100;
    &:hover {
      cursor: pointer;
      box-shadow:
        4px 10px 20px 0px rgba(255, 158, 42, 0.2),
        inset 0px 6px 24px 0px rgba(255, 215, 164, 1),
        4px 10px 50px 0px rgba(255, 158, 42, 0.5);
    }
  }
}
</style>
