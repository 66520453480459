<script setup lang="ts">
import { socket } from '@/services/socket'
import { onMounted } from 'vue'
import { usePhoneStore } from '@/stores/phone'

const phoneStore = usePhoneStore()

onMounted(() => {
  console.log('app.vue mounted')
  socket.on('callStatus', (data) => {
    // Handle the received call status update
    console.log('Received call status update:', data)
    phoneStore.updateCallStatus(data.callSid, data.callStatus)
  })

  socket.on('callStatusOneCall', (data) => {
    // Handle the received call status update
    console.log('Received call status update [one call]:', data)
    phoneStore.updateCallStatusOneCall(data.callSid, data.callStatus)
  })

  socket.on('callbackCallStatus', (data) => {
    console.log('Received callback call status update:', data)
    phoneStore.updateCallStatusFromCallback(data.prospectId, data.sessionId, data.callStatus)
  })

  socket.on('transcription', (data) => {
    console.log('Received transcription:', data)
    phoneStore.updateTranscription(data)
  })

  socket.on('transcriptionReady', (data) => {
    console.log('Received recording transcription:', data)
    phoneStore.updateRecordingTranscription(data)
  })
})
</script>

<template>
  <router-view></router-view>
</template>

<style scoped></style>
