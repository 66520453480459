<template>
  <div class="analytics-details">
    <analytics-date-range-selector
      @update:value="updateDateValue"
      :initial-value="analyticsStore.optionName"
      :initial-custom-start-date="analyticsStore.startDate"
      :initial-custom-end-date="analyticsStore.endDate"
      :allPeriodStartDate="analyticsStore.allPeriodStartDate"
    />
    <call-logs-table
      :loading="loading"
      :call-logs="callLogs"
      :current-page="currentPage"
      :page-size="pageSize"
      :total-records="totalRecords"
      @update:page="updatePage"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from './services/axiosService'
import CallLogsTable from './components/CallLogsTable.vue'
import { useRoute } from 'vue-router'
import { useAnalyticsStore } from './stores/analyticsStore'
import AnalyticsDateRangeSelector from './components/AnalyticsDateRangeSelector.vue'
import { useSessionStore } from './stores/sessionStore'

const analyticsStore = useAnalyticsStore()

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  logType: {
    type: String,
    required: true
  }
})

const route = useRoute()

function updateDateValue(updatedDate) {
  analyticsStore.startDate = updatedDate.startDate
  analyticsStore.endDate = updatedDate.endDate
  analyticsStore.optionName = updatedDate.optionName
}

const ready = ref(false) // This flag ensures the component is fully initialized

// Watch for changes to reactive properties and fetch call logs when they are ready
watch(
  () => [analyticsStore.startDate, analyticsStore.endDate, ready.value],
  ([startDate, endDate, isReady]) => {
    if (isReady && startDate && endDate) {
      fetchCallLogs() // Fetch logs when all required properties are ready
    }
  }
)

const sessionStore = useSessionStore()

onMounted(() => {
  analyticsStore.minDuration = 0
  analyticsStore.statuses = []
  analyticsStore.callDirection = ''
  switch (props.logType) {
    case 'dials':
      break
    case 'connects':
      analyticsStore.statuses = ['connected', 'meeting_booked']
      break
    case 'callbacks':
      analyticsStore.callDirection = 'incoming'
      break
    case 'callbackConnects':
      analyticsStore.callDirection = 'incoming'
      analyticsStore.minDuration = sessionStore.conversationThreshold
      break
    case 'conversations':
      analyticsStore.statuses = ['connected', 'meeting_booked']
      analyticsStore.minDuration = sessionStore.conversationThreshold
      break
    case 'meetingBooked':
      analyticsStore.statuses = ['meeting_booked']
      break
    default:
      console.error(`Unknown log type ${props.logType}`)
  }
  console.log('after onMounted')
  ready.value = true // Mark the component as ready
})

const loading = ref(true)

const callLogs = ref([])

const totalRecords = ref(0)
const currentPage = ref(1)
const pageSize = ref(50)

function updatePage(args) {
  currentPage.value = args.currentPage
  pageSize.value = args.pageSize
  fetchCallLogs()
}

async function fetchCallLogs() {
  try {
    loading.value = true
    const response = await axios.post(`/api/call-logs/`, {
      page: currentPage.value,
      limit: pageSize.value,
      statuses: analyticsStore.statuses,
      startDate: analyticsStore.startDate,
      endDate: analyticsStore.endDate,
      minDuration: analyticsStore.minDuration,
      callDirection: analyticsStore.callDirection,
      userIds: [Number(props.userId)]
    })

    callLogs.value = response.data.calls
    totalRecords.value = response.data.totalRecords
    currentPage.value = response.data.currentPage
    pageSize.value = response.data.pageSize
    analyticsStore.setUserName(
      response.data.allCompanyUsers.find((user) => user.value === Number(props.userId)).label
    )
    analyticsStore.allPeriodStartDate = response.data.allPeriodStartDate
  } catch (error) {
    console.error('Failed to fetch call logs:', error)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.analytics-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-height: 0;
  flex: 1 1 auto;
}
</style>
