<template>
  <div class="setting__container">
    <div class="setting__title">Redirect number</div>

    <div class="setting__subtitle">
      When a contact calls you back, they will be redirected to this phone number.
    </div>

    <div v-if="authStore.loading" class="loader"><Loader :size="20"></Loader></div>
    <div v-else class="setting__body">
      <div class="setting__item">
        <div>Redirect callbacks</div>
        <ToggleSwitch
          :modelValue="authStore.redirectCallback"
          @update:modelValue="authStore.updateRedirectCallback"
        />
      </div>
      <div
        class="setting__item"
        style="min-height: 50px"
        :class="{ 'setting__item--disabled': !authStore.redirectCallback }"
      >
        <div>Redirect number</div>
        <div class="setting__redirect-number">
          <div v-if="!editingRedirectNumber" class="setting__redirect-number-container">
            <span>{{ formatPhoneNumber(authStore.redirectNumber) }}</span>
            <div class="edit-icon-section" @click="startEditingRedirectNumber">
              <EditIcon class="edit-icon" size="20" />
            </div>
          </div>

          <div v-else class="redirect-input-section">
            <div class="redirect-input">
              <InputText
                v-model="newRedirectNumber"
                style="width: 150px"
                :class="{
                  'p-invalid': !isPhoneNumberValid(newRedirectNumber) && newRedirectNumber
                }"
              />
              <small
                v-if="!isPhoneNumberValid(newRedirectNumber) && newRedirectNumber"
                class="p-error"
              >
                Invalid phone number
              </small>
            </div>

            <Button
              label="Save"
              :disabled="!isPhoneNumberValid(newRedirectNumber)"
              icon="pi pi-save"
              v-if="editingRedirectNumber"
              @click="saveRedirectNumber"
            />

            <Button
              label="Cancel"
              icon="pi pi-times"
              v-if="editingRedirectNumber"
              @click="cancelEditingRedirectNumber"
              severity="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import Loader from '../Loader.vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import ToggleSwitch from 'primevue/toggleswitch'
import EditIcon from '../icons/EditIcon.vue'
import { assertIsPhoneNumberValid, formatPhoneNumber } from '@/helpers/phoneNumber'
import { useAuthStore } from '../../stores/auth'

const authStore = useAuthStore()

const editingRedirectNumber = ref(false)
const newRedirectNumber = ref(null)

const startEditingRedirectNumber = () => {
  if (!authStore.redirectCallback) {
    return
  }
  editingRedirectNumber.value = true
  newRedirectNumber.value = authStore.redirectNumber
}

const isPhoneNumberValid = (phoneNumber) => {
  try {
    assertIsPhoneNumberValid(phoneNumber)
    return true
  } catch {
    return false
  }
}

const saveRedirectNumber = async () => {
  try {
    await authStore.updateRedirectNumber(newRedirectNumber.value)

    editingRedirectNumber.value = false
  } catch (error) {
    console.error('Error editing phone number redirect:', error)
  }
}

const cancelEditingRedirectNumber = () => {
  editingRedirectNumber.value = false
  newRedirectNumber.value = '' // Clear new redirect number
}
</script>

<style scoped lang="scss">
.setting__container {
  padding: 36px 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 8px;
  .setting__title {
    color: #212223;
    font-size: 18px;
    font-weight: 500;
    display: flex;
  }

  .setting__subtitle {
    display: flex;
    align-items: center;
    color: #667085;
    font-weight: 500;
  }

  .loader {
    margin-top: 16px;
    align-self: center;
  }

  .setting__body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex: 1;
    margin-top: 16px;

    .setting__item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 6px;
      padding: 8px 0;
      border-bottom: 1px solid rgba(55, 53, 47, 0.09);
      .edit-icon {
        stroke: #667085;
      }
      .edit-icon-section {
        border-radius: 4px;
        padding: 2px;
        display: flex;
        align-items: center;
        margin-left: 8px;
        &:hover {
          cursor: pointer;
          background: rgba(55, 53, 47, 0.08);
          transition: background 0.02s ease;
        }
      }
      &--disabled {
        color: #b8b8b8;
        .edit-icon {
          stroke: #b8b8b8;
        }
        .edit-icon-section {
          &:hover {
            cursor: unset;
            background: unset;
          }
        }
      }
      &:last-child {
        border: none;
      }
      .setting__redirect-number {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .setting__redirect-number-container {
          display: flex;
          gap: 8px;
        }
        .redirect-input-section {
          display: flex;
          align-items: start;
          gap: 8px;
          .redirect-input {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
