import { assertIsPhoneNumberValid } from '@/helpers/phoneNumber'
import axiosInstance from './axiosService'

export async function updatePhoneNumberField(
  contact,
  phoneNumberField,
  value,
  phoneNumberFieldSelectedId
) {
  await axiosInstance.post(`/api/prospects/${contact.id}/update-phone-number`, {
    field: phoneNumberField,
    value
  })
  if (phoneNumberFieldSelectedId === phoneNumberField) {
    contact.phoneNumber = value
  }
  const potentialExistingPhoneNumber = contact.phoneNumbers.find(
    (phone) => phone.key === phoneNumberField
  )
  if (potentialExistingPhoneNumber) {
    potentialExistingPhoneNumber.value = value
  } else {
    contact.phoneNumbers.push({ key: phoneNumberField, value })
  }
  try {
    assertIsPhoneNumberValid(value)
    contact.wrongNumber = false
  } catch (error) {
    console.error(`invalid phone number`, error)
    contact.wrongNumber = true
  }

  console.log('Field updated')
}
