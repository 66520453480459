import axios from './axiosService'

export default {
  install(Vue, options) {
    async function initVersion() {
      console.log('initVersion')
      const response = await axios.get('/version.json', { cache: 'no-store' })
      const { data } = response
      localStorage.setItem('app-version', data.version)
    }
    async function checkVersion() {
      try {
        console.log('Checking version')
        const response = await axios.get('/version.json', { cache: 'no-store' })
        const { data } = response
        const currentVersion = localStorage.getItem('app-version')
        if (currentVersion && currentVersion !== data.version) {
          console.log('New version detected, reloading...')
          await axios.get('/version.json?reloading')
          window.location.reload(true)
        }
      } catch (err) {
        console.error('Failed to check app version', err)
      }
    }

    initVersion()

    setInterval(checkVersion, 10 * 60 * 1000) // Check every 10 minutes
  }
}
