<template>
  <div>
    <the-menu @toggleMenu="isMenuOpen = !isMenuOpen" :isMenuOpen="isMenuOpen" />
    <main
      class="main-view"
      :style="{
        width: isMenuOpen ? 'calc(100vw - 190px)' : 'calc(100vw - 60px)',
        'margin-left': isMenuOpen ? '190px' : '60px'
      }"
    >
      <router-view></router-view>
    </main>
    <transition name="message-appear">
      <message-notification
        v-if="phoneStore.errorMessage"
        :text="phoneStore.errorMessage"
        @close="phoneStore.errorMessage = ''"
      />
      <message-notification
        v-else-if="sessionStore.errorMessage"
        :text="sessionStore.errorMessage"
        @close="sessionStore.errorMessage = ''"
      />
      <success-message-notification
        v-else-if="phoneStore.successMessage"
        :text="phoneStore.successMessage"
      />
    </transition>
    <search-contact @select:value="selectContactFromSearch" />
    <row-modal
      :is-modal-open="contactStore.oneCallContact !== null || contactStore.contactModalLoading"
      :loading="contactStore.contactModalLoading"
      :row-data="contactStore.oneCallContact"
      @close-modal="closeModal"
      :headers="contactStore.headers"
      @saveNewInfo="contactStore.updateField"
      @saveNote="contactStore.updateNote"
      @deleteNote="contactStore.deleteNote"
      @pinNote="contactStore.pinNote"
      @saveLog="contactStore.updateLog"
      @saveCallDisposition="contactStore.updateCallDisposition"
      @createNewNote="contactStore.createNewNote"
      @saveNewTask="contactStore.saveNewTask"
      @updateTask="contactStore.updateTask"
      callable
      :meeting-booked="contactStore.getMeetingBooked"
      @toggleMeetingBooked="toggleMeetingBooked"
    />

    <div class="notification__group">
      <incoming-call-notification-modal
        v-for="incomingCall in phoneStore.gg_contactList.filter(
          (item) => item.incoming && item.dialStatus === 'ringing'
        )"
        :key="incomingCall.callSid"
        :name="`${incomingCall.firstName} ${incomingCall.lastName}`"
        :phoneNumber="incomingCall.phoneNumber"
        @end-call="endIncomingCall(incomingCall.callSid)"
        @start-call="startIncomingCall(incomingCall.call)"
      />
      <notification-modal
        v-if="phoneStore.gg_activeCall.isActive"
        :name="notificationModalName"
        :phoneNumber="phoneStore.getActiveCallPhoneNumber"
        :isMuted="phoneStore.isMuted"
        :is-calling-in-session="!phoneStore.isCallingFromModal"
        @hangup-and-resume="phoneStore.hangupAndResume"
        @mute-call="phoneStore.muteCall"
        @end-call="endSessionCall"
      />
      <calling-notification-modal
        v-if="phoneStore.callFromModal"
        :name="callNotificationModalName"
        :phoneNumber="phoneStore.callFromModal.phoneNumberFromModal"
        @end-call="endCallFromModal(phoneStore.callFromModal.callSid)"
      />
    </div>
    <Toast position="bottom-right" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import TheMenu from './components/TheMenu.vue'
import Toast from 'primevue/toast'
import MessageNotification from './components/MessageNotification.vue'
import SuccessMessageNotification from './components/SuccessMessageNotification.vue'
import { usePhoneStore } from '@/stores/phone'
import { useSessionStore } from '@/stores/sessionStore'
import SearchContact from './components/SearchContact.vue'
import NotificationModal from './components/NotificationModal.vue'
import IncomingCallNotificationModal from './components/IncomingCallNotificationModal.vue'
import CallingNotificationModal from './components/CallingNotificationModal.vue'
import RowModal from './components/RowModal.vue'
import { useContactStore } from './stores/contactStore'

const phoneStore = usePhoneStore()
const contactStore = useContactStore()
const sessionStore = useSessionStore()

const isMenuOpen = ref(false)

function selectContactFromSearch(contact) {
  const contactInSession = phoneStore.gg_contactList.find((item) => item.sourceId === contact.id)
  if (contactInSession) {
    contactStore.searchContactOpened = false
    phoneStore.openRowModal(contactInSession)
  } else {
    contactStore.fetchContact(contact)
  }
}

function endSessionCall() {
  if (phoneStore.isCallingFromModal) {
    phoneStore.endOneCall()
  } else {
    phoneStore.endCall()
  }
}

function endCallFromModal(callSid) {
  phoneStore.endOneCall(callSid)
}

function endIncomingCall(callSid) {
  phoneStore.cancelCall(callSid)
}

function startIncomingCall(call) {
  phoneStore.startIncomingCall(call)
}

const notificationModalName = computed(() => {
  if (phoneStore.getActiveCall.firstName && phoneStore.getActiveCall.lastName) {
    return `${phoneStore.getActiveCall.firstName} ${phoneStore.getActiveCall.lastName}`
  }
  return ''
})

const callNotificationModalName = computed(() => {
  if (phoneStore.callFromModal.firstName && phoneStore.callFromModal.lastName) {
    return `${phoneStore.callFromModal.firstName} ${phoneStore.callFromModal.lastName}`
  }
  return ''
})

function closeModal() {
  if (!phoneStore.gg_activeCall.isActive && !phoneStore.isDialing) {
    contactStore.resetOneContact()
  }
}

async function toggleMeetingBooked() {
  await contactStore.toggleMeetingBooked()
  if (contactStore.getMeetingBooked) {
    const count = 200,
      defaults = {
        origin: { y: 0.7 }
      }

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        })
      )
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55
    })

    fire(0.2, {
      spread: 60
    })

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }
}
</script>

<style scoped lang="scss">
.notification__group {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 999;
}

.main-view {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  height: 100vh;
  transition:
    width 0.27s ease,
    margin-left 0.27s ease;
}

.message-appear-enter-active,
.message-appear-leave-active {
  transition: opacity 0.4s ease;
}
.message-appear-enter-from,
.message-appear-leave-to {
  opacity: 0;
}
</style>
