<script setup>
import { formatDateWithoutSeconds } from './helpers/date'
import TheVoicemailSelector from './components/TheVoicemailSelector.vue'
import ImportList from './components/ImportList.vue'
import AddContacts from './components/AddContacts.vue'
import ThePhoneNumberDropdown from './components/ThePhoneNumberDropdown.vue'
import TheParallelSetting from './components/TheParallelSetting.vue'
import PhoneIcon from './components/icons/PhoneIcon.vue'
import TargetIcon from './components/icons/TargetIcon.vue'
import RefreshIcon from './components/icons/RefreshIcon.vue'
import HangUpIcon from './components/icons/HangUpIcon.vue'
import TheProgressBar from './components/TheProgressBar.vue'
import EmptyDropdown from './components/EmptyDropdown.vue'
import ThePhoneNumberFormattingModal from './components/ThePhoneNumberFormattingModal.vue'
import Dropdown from './components/Dropdown.vue'
import InputSwitch from 'primevue/inputswitch'
import InlineMessage from 'primevue/inlinemessage'

import { directive as vAutowidth } from 'vue-input-autowidth'

import { usePhoneStore } from '@/stores/phone'
import { useSessionStore } from '@/stores/sessionStore'
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'

import TheTableContainer from './components/TheTableContainer.vue'
import { useListStore } from '@/stores/listStore'
import { useContactStore } from './stores/contactStore'
import axiosInstance from './services/axiosService'

const listStore = useListStore()

const handleBeforeUnload = (event) => {
  if (phoneStore.isDialingOrCalling) {
    event.returnValue = true
    return
  }
  return
}

function removeCustomSort() {
  phoneStore.sortByField = null
  phoneStore.sortByStatus()
}

const initialCallAttemptLimit = computed(() =>
  sessionStore.maxCallAttemptLimitList.find((item) => item.id === sessionStore.maxCallAttempts)
)

const initialCallAttemptPeriod = computed(() =>
  sessionStore.maxCallAttemptPeriodList.find((item) => item.id === sessionStore.callAttemptPeriod)
)

const contactStore = useContactStore()

const refreshButtonTooltip = computed(
  () => `Last refresh: ${formatDateWithoutSeconds(new Date(sessionStore.lastRefresh))}`
)

function updatePhoneNumberColumn(column) {
  sessionStore.updatePhoneNumberFieldSetting(column, phoneStore.getCurrentIntegrationName)
  phoneStore.setPhoneNumberField(column)
  phoneStore.computeNumberStatuses()
  phoneStore.sortByStatus()
}

const handleOnline = () => {
  console.log('Became online')
  console.log('initializedDevice handleOnline')
  phoneStore.initializedDevice()
}

const handleOffline = () => {
  console.log('Became offline')
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload)
  window.addEventListener('online', handleOnline)
  window.addEventListener('offline', handleOffline)
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
  window.removeEventListener('online', handleOnline)
  window.removeEventListener('offline', handleOffline)
})

onBeforeUnmount(() => {
  phoneStore.sortByField = null
})

const phoneStore = usePhoneStore()
const sessionStore = useSessionStore()

const connected = computed(
  () => phoneStore.filteredRows.filter((item) => item.dialStatus === 'connected').length
)

const attemptedStatuses = ['canceled', 'no-answer', 'busy', 'voicemail', 'left-voicemail']

const attempted = computed(
  () => phoneStore.filteredRows.filter((item) => attemptedStatuses.includes(item.dialStatus)).length
)

const pending = computed(
  () =>
    phoneStore.filteredRows.filter((item) => item.dialStatus === 'pending' && !item.errorStatus)
      .length
)

const total = computed(
  () =>
    phoneStore.filteredRows.filter(
      (item) =>
        ['connected', ...attemptedStatuses].includes(item.dialStatus) ||
        (item.dialStatus === 'pending' && !item.errorStatus)
    ).length
)

const numberOfContact = computed(() => {
  const contactLength = phoneStore.filteredRows.length
  if (contactLength === 0) {
    return ''
  }
  if (contactLength === 1) {
    return '1 contact'
  }
  return `${contactLength} contacts`
})

const showSessionTitle = computed(() => sessionStore.currentSessionId && sessionStore.sessionName)

onMounted(() => {
  if (phoneStore.gg_contactList.length > 0) {
    return
  }
  phoneStore.initContactList()
  sessionStore.initCurrentSessionSetting()
  console.log('initializedDevice main.vue mounted')
  phoneStore.initializedDevice()
})

function cancelCalls() {
  phoneStore.cancellingCalls = true
  phoneStore.cancelCalls()
}

function startCalling() {
  if (phoneStore.isDialingOrCalling) {
    return
  }

  if (phoneStore.gg_contactList.length === 0) {
    return
  }

  if (phoneStore.contactLoading) {
    return
  }

  phoneStore.startCallFromTable()
}

const callButtonClass = () => {
  if (phoneStore.isDialing) {
    return 'header__button--cancel-calls'
  }
  if (phoneStore.isDialingOrCalling) {
    return 'header__button--dialing'
  }
  if (phoneStore.gg_contactList.length && !phoneStore.computeNextContactId) {
    return 'header__button--dialing'
  }
  if (phoneStore.hasSessionStarted) {
    return 'header__button--resume'
  }
  if (phoneStore.gg_contactList.length === 0 || phoneStore.contactLoading) {
    return 'header__button--empty-table'
  }
  return 'header__button--start'
}

const sessionNameCopy = ref('')

sessionNameCopy.value = sessionStore.sessionName

watch(
  () => sessionStore.sessionName,
  () => {
    sessionNameCopy.value = sessionStore.sessionName
  }
)

function keyUpEnter(event) {
  event.target.blur()
}

async function updateSessionName(event, newSessionName) {
  try {
    if (newSessionName !== sessionStore.sessionName) {
      sessionStore.sessionName = newSessionName
      if (sessionStore.sessions[sessionStore.currentSessionId]) {
        sessionStore.sessions[sessionStore.currentSessionId].sessionName = newSessionName
      }
      await sessionStore.updateSessionSettings({ sessionName: newSessionName })
    }
  } catch (error) {
    console.error('error while updating session name', error)
    sessionNameCopy.value = sessionStore.sessionName
  }
}

async function refreshList() {
  listStore.refreshList()
}
</script>

<template>
  <div class="dialer">
    <div class="dialer__header">
      <div class="dialer__header-top">
        <div class="dialer__header-title-section">
          <import-list @import-list="listStore.importNewList" />
          <input
            v-if="showSessionTitle"
            type="text"
            class="dialer__session-title"
            v-model="sessionNameCopy"
            v-autowidth="{
              minWidth: '100px',
              comfortZone: '1ch'
            }"
            @blur="updateSessionName($event, sessionNameCopy)"
            @keyup.enter="keyUpEnter($event)"
          />
          <input
            disabled
            type="text"
            :value="sessionStore.tempSessionName"
            v-autowidth="{
              minWidth: '100px',
              comfortZone: '1ch'
            }"
            v-else
            class="dialer__session-title dialer__session-title--disabled"
          />
          <div class="dialer__session-contacts">{{ numberOfContact }}</div>

          <div
            v-if="sessionStore.refreshable && !phoneStore.contactLoading"
            class="refresh__button"
            @click="refreshList"
            v-tooltip.top="{
              value: refreshButtonTooltip,
              pt: {
                root: 'large-tooltip'
              }
            }"
          >
            <RefreshIcon size="16" style="stroke: #667085" /> Refresh
          </div>
          <add-contacts
            v-show="false && sessionStore.currentSessionId && !phoneStore.contactLoading"
            @import-list="listStore.addContacts"
          />
        </div>

        <the-progress-bar
          class="the-progress-bar"
          :connected="connected"
          :attempted="attempted"
          :pending="pending"
          :total="total"
        />
      </div>

      <div class="dialer__call-section">
        <div class="dialer__header--first-row">
          <div class="dialer__header-button-group">
            <div
              class="header__button header__button--dialing"
              v-if="phoneStore.isDialing && phoneStore.cancellingCalls"
            >
              <div class="button__content">
                <HangUpIcon class="button__icon button__icon--grey" size="20" />
                Canceling calls...
              </div>
            </div>
            <div
              class="header__button header__button--cancel-calls"
              v-else-if="phoneStore.isDialing"
              @click="cancelCalls"
            >
              <div class="button__content">
                <HangUpIcon class="button__icon button__icon--red" size="20" />
                Cancel calls
              </div>
            </div>
            <div v-else class="header__button" :class="callButtonClass()" @click="startCalling">
              <!-- <transition name="fade-button-transition" mode="out-in"> -->
              <div class="button__content" v-if="phoneStore.isDialingOrCalling">
                <PhoneIcon class="button__icon button__icon--grey" size="20" />
                Call in progress
              </div>
              <div
                class="button__content"
                v-else-if="phoneStore.gg_contactList.length && !phoneStore.computeNextContactId"
              >
                <PhoneIcon class="button__icon button__icon--grey" size="20" />Session finished
              </div>
              <div class="button__content" v-else-if="phoneStore.hasSessionStarted">
                <PhoneIcon class="button__icon button__icon--white" size="20" />Resume calling
              </div>
              <div class="button__content" v-else>
                <PhoneIcon class="button__icon button__icon--white" size="20" />Start calling
              </div>
              <!-- </transition> -->
            </div>
            <!-- <transition name="button-transition" mode="out-in"> -->

            <div
              class="redial"
              v-if="
                !phoneStore.isDialingOrCalling &&
                phoneStore.computeNextCanceledContactId &&
                phoneStore.redialCanceledCalls
              "
            >
              <InlineMessage severity="info"
                ><div class="redial-info-text">Redialing calls</div></InlineMessage
              >
            </div>
            <!-- </transition> -->
            <!-- <transition name="button-transition" mode="out-in"> -->
            <div
              v-if="
                phoneStore.hasSessionStarted &&
                !phoneStore.isDialingOrCalling &&
                phoneStore.countMeetingBooked
              "
              class="header__meeting-booked"
            >
              <TargetIcon class="header__meeting-booked-icon" size="14" />
              {{ phoneStore.countMeetingBooked }} meeting{{
                phoneStore.countMeetingBooked > 1 ? 's' : ''
              }}
            </div>
            <div
              v-if="!phoneStore.isDialingOrCalling && phoneStore.sortByField"
              class="dialer__header--second-row"
            >
              <div>Sorted by</div>
              <div class="chip-container">
                <span class="chip">
                  {{ phoneStore.sortByField?.sortLabel }}

                  <svg
                    @click.stop="removeCustomSort"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0052 7.7019C13.2005 7.50664 13.2005 7.19006 13.0052 6.9948C12.8099 6.79953 12.4934 6.79953 12.2981 6.9948L13.0052 7.7019ZM6.9948 12.2981C6.79953 12.4934 6.79953 12.8099 6.9948 13.0052C7.19006 13.2005 7.50664 13.2005 7.7019 13.0052L6.9948 12.2981ZM12.2981 13.0052C12.4934 13.2005 12.8099 13.2005 13.0052 13.0052C13.2005 12.8099 13.2005 12.4934 13.0052 12.2981L12.2981 13.0052ZM7.7019 6.9948C7.50664 6.79953 7.19006 6.79953 6.9948 6.9948C6.79953 7.19006 6.79953 7.50664 6.9948 7.7019L7.7019 6.9948ZM17 10C17 13.866 13.866 17 10 17V18C14.4183 18 18 14.4183 18 10H17ZM10 17C6.13401 17 3 13.866 3 10H2C2 14.4183 5.58172 18 10 18V17ZM3 10C3 6.13401 6.13401 3 10 3V2C5.58172 2 2 5.58172 2 10H3ZM10 3C13.866 3 17 6.13401 17 10H18C18 5.58172 14.4183 2 10 2V3ZM12.2981 6.9948L9.64645 9.64645L10.3536 10.3536L13.0052 7.7019L12.2981 6.9948ZM9.64645 9.64645L6.9948 12.2981L7.7019 13.0052L10.3536 10.3536L9.64645 9.64645ZM13.0052 12.2981L10.3536 9.64645L9.64645 10.3536L12.2981 13.0052L13.0052 12.2981ZM10.3536 9.64645L7.7019 6.9948L6.9948 7.7019L9.64645 10.3536L10.3536 9.64645Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <!-- </transition> -->
          </div>
          <div class="header__settings">
            <!-- <div class="amd-switch">
            AMD
            <InputSwitch v-model="phoneStore.useAmdFeature" />
          </div> -->
            <div class="header__parallel-setting">
              <TheParallelSetting :disable="phoneStore.isDialingOrCalling" />
            </div>
            <div class="header__voicemail-setting">
              <TheVoicemailSelector :disable="phoneStore.isDialingOrCalling" />
            </div>
            <div class="header__phone-number-setting">
              <ThePhoneNumberDropdown :disable="phoneStore.isDialingOrCalling" />
            </div>
            <empty-dropdown class="setting-dropdown" alignRight dropdownTitle="Advanced settings">
              <div class="setting-dropdown--open">
                <!-- <div class="setting-item">
                  <div class="setting__text">Redial canceled calls</div>
                  <div class="setting__switch">
                    <InputSwitch
                      :disabled="phoneStore.isDialingOrCalling"
                      v-model="phoneStore.redialCanceledCalls"
                    />
                  </div>
                </div> -->
                <div class="setting-item">
                  <div class="setting__text">Contact phone number field</div>
                  <div class="setting__switch" v-if="sessionStore.phoneNumberList.length">
                    <dropdown
                      class="log__body-item-value"
                      :items="sessionStore.phoneNumberList"
                      :initial-value="sessionStore.phoneNumberFieldSelected"
                      @update:value="updatePhoneNumberColumn"
                    />
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting__text">Complete task when contact dialed</div>
                  <div class="setting__switch">
                    <InputSwitch
                      :disabled="phoneStore.isDialingOrCalling"
                      :modelValue="sessionStore.completeTaskWhenDialed"
                      @update:modelValue="
                        ($events) => sessionStore.updateSetting('completeTaskWhenDialed', $events)
                      "
                    />
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting__text">Auto-rotate caller phone numbers</div>
                  <div class="setting__switch">
                    <InputSwitch
                      :disabled="phoneStore.isDialingOrCalling"
                      :modelValue="sessionStore.rotateFromPhoneNumber"
                      @update:modelValue="
                        ($events) => sessionStore.updateSetting('rotateFromPhoneNumber', $events)
                      "
                    />
                  </div>
                </div>
                <div class="setting-item setting-item--column">
                  <div class="setting__text--column">Maximum call attempts per contact</div>

                  <div class="setting__call-attempt">
                    <dropdown
                      class="log__body-item-value"
                      :items="sessionStore.maxCallAttemptLimitList"
                      :initial-value="initialCallAttemptLimit"
                      :disabled="phoneStore.contactLoading"
                      @update:value="
                        ($events) => sessionStore.updateSetting('maxCallAttempts', $events.id)
                      "
                    />

                    per

                    <dropdown
                      class="log__body-item-value"
                      :items="sessionStore.maxCallAttemptPeriodList"
                      :initial-value="initialCallAttemptPeriod"
                      :disabled="phoneStore.contactLoading"
                      @update:value="
                        ($events) => sessionStore.updateSetting('callAttemptPeriod', $events.id)
                      "
                    />
                  </div>
                </div>
              </div>
            </empty-dropdown>
          </div>
        </div>
      </div>
    </div>
    <TheTableContainer />
    <the-phone-number-formatting-modal />
  </div>
</template>

<style lang="scss">
.dialer {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
  min-height: 0;
  flex: 1 1 auto;
  .dialer__header {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaecf0;
    border-radius: 8px 8px 0 0;
    font-weight: 500;

    flex: 0 0 auto;
    align-items: flex-start;

    .dialer__header-top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #eaecf0;
      padding: 24px;

      .dialer__header-title-section {
        display: flex;
        align-items: center;
        color: #667085;
        .dialer__session-contacts {
          font-weight: 600;
          margin-left: 8px;
          margin-right: 16px;
        }

        .refresh__button {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          border: 1px solid #cbd5e1;
          border-radius: 4px;
          padding: 2px 8px;
          font-size: 12px;
          color: #667085;
          background-color: #f5f6f8;
        }
      }
    }

    .dialer__session-title {
      color: #344054;
      font-size: 20px;
      font-weight: 600;

      display: flex;

      line-height: 1.6;
      font-family:
        Inter,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;

      resize: vertical;

      border: 1px solid #fff;
      border-radius: 4px;

      &:hover {
        border: 1px solid #dbdbdb;
      }

      &--disabled {
        background-color: #ffffff;
      }

      &--disabled:hover {
        border: 1px solid #fff;
      }

      &:focus {
        border: 1px solid #0070ff;
        outline: 2px solid #cbe2ff;
      }
    }
  }
  .dialer__call-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 12px 24px 24px 24px;
    gap: 12px;

    .dialer__header--first-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }

    .dialer__header--second-row {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      font-size: 12px;

      .chip-container {
        display: flex;
        gap: 5px;

        .chip {
          display: flex;
          align-items: center;
          cursor: default;
          text-wrap: nowrap;

          svg {
            margin-left: 4px;
            &:hover {
              cursor: pointer;
            }
          }
          padding: 2px 10px;
          color: #181818;
          background-color: #f4f4f4;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
      }
    }
  }
  .dialer__header-button-group {
    display: flex;
    white-space: nowrap;
    gap: 16px;
    flex-wrap: wrap;
  }

  .header__settings {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .setting-dropdown {
      align-self: flex-end;
      &--open {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      .setting-item {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        font-weight: 500;
        font-size: 14px;
        padding: 12px;
        line-height: 1.4;
        min-width: 230px;
        border-bottom: 1px solid #d0d5dd;
        gap: 24px;
        &:last-child {
          border-bottom: none;
        }

        &--column {
          align-items: start;
          gap: 24px;
        }

        .setting__text--column {
          width: 170px;
        }

        .setting__switch {
          display: flex;
          align-items: center;
        }

        .setting__call-attempt {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  .header__phone-number-setting {
  }
  .header__voicemail-setting {
  }
  .header__button {
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    // transition:;
    // background-color 0.4s ease,
    // border-color 0.4s ease;
  }
  .header__session-button {
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #ff3e32;
    border: 1px solid #dbdbdb;
  }
  .header__meeting-booked {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #9e00ff;
  }
  .header__meeting-booked-icon {
    fill: #9e00ff;
    margin-right: 3px;
  }
  .header__session-button:hover {
    border: solid 1px #c2c2c2;
    cursor: pointer;
  }

  .header__button--resume:hover,
  .header__button--start:hover {
    background: #006fe6;
    cursor: pointer;
  }

  .header__button--cancel-calls {
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #ff3e32;
    border: 1px solid #dbdbdb;

    &:hover {
      border: solid 1px #c2c2c2;
      cursor: pointer;
    }
  }
  .header__button--dialing {
    color: #b8b8b8;
    background: #f6f6f6;
    user-select: none;
    border: 1px solid #f6f6f6;
  }
  .header__button--resume,
  .header__button--start {
    background: #0070ff;
    border: 1px solid #0070ff;
    color: #fff;
  }
  .header__button--empty-table {
    background: #64a5fa;
    border: 1px solid #64a5fa;
    color: #fff;
    user-select: none;
  }
  .header__session-icon {
    stroke: #ff3e32;
    margin-right: 7px;
  }
  .button__icon {
    margin-right: 7px;
    /* transition: all 4s linear 4s; */
  }
  .button__icon--white {
    stroke: #fff;
  }
  .button__icon--grey {
    stroke: #b8b8b8;
  }
  .button__icon--red {
    stroke: #ff3e32;
  }
  .header__button--empty-table .button__icon,
  .header__button--start .button__icon,
  .header__button--resume .button__icon {
    /* transition: all 0.27s ease; */
    stroke: #fff;
  }

  .header__button--dialing .button__icon {
    /* transition: all 0.27s ease; */
    stroke: #b8b8b8;
  }
  .dialer__table {
    /* width: 100%; */
    /* border-right: 1px solid #eaecf0;
    border-left: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
    border-radius: 0 0 8px 8px; */
  }

  .fade-button-transition-enter-active,
  .fade-button-transition-leave-active {
    transition: opacity 0.2s ease;
    /* stroke 2s ease; */
  }
  .fade-button-transition-enter-from,
  .fade-button-transition-leave-to {
    opacity: 0;
  }

  .button-transition-enter-active,
  .button-transition-leave-active {
    transition: opacity 0.4s ease;
    /* stroke 2s ease; */
  }
  .button-transition-enter-from,
  .button-transition-leave-to {
    opacity: 0;
  }

  .the-progress-bar {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }
  .button__content {
    display: flex;
    align-items: center;
  }

  .amd-switch {
    display: flex;
    align-items: center;
    margin-right: 40px;
    color: #344054;
  }

  .p-inputswitch {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .p-inputswitch.p-highlight .p-inputswitch-slider {
    background: #313131;
  }
}

.redial {
  display: flex;
  align-items: center;

  .redial-info-text {
    font-size: 14px;
    margin-left: 4px;
  }
}
</style>
